const NodeShaderStage = {
  Vertex: 'vertex',
  Fragment: 'fragment'
};
const NodeUpdateType = {
  None: 'none',
  Frame: 'frame',
  Object: 'object'
};
const NodeType = {
  Float: 'float',
  Vector2: 'vec2',
  Vector3: 'vec3',
  Vector4: 'vec4',
  Matrix3: 'mat3',
  Matrix4: 'mat4'
};

export { NodeShaderStage, NodeType, NodeUpdateType };
