import { FloatUniform, Vector2Uniform, Vector3Uniform, Vector4Uniform, ColorUniform, Matrix3Uniform, Matrix4Uniform } from '../WebGPUUniform.js';

class FloatNodeUniform extends FloatUniform {
  constructor(nodeUniform) {
    super(nodeUniform.name, nodeUniform.value);
    this.nodeUniform = nodeUniform;
  }

  getValue() {
    return this.nodeUniform.value;
  }

}

class Vector2NodeUniform extends Vector2Uniform {
  constructor(nodeUniform) {
    super(nodeUniform.name, nodeUniform.value);
    this.nodeUniform = nodeUniform;
  }

  getValue() {
    return this.nodeUniform.value;
  }

}

class Vector3NodeUniform extends Vector3Uniform {
  constructor(nodeUniform) {
    super(nodeUniform.name, nodeUniform.value);
    this.nodeUniform = nodeUniform;
  }

  getValue() {
    return this.nodeUniform.value;
  }

}

class Vector4NodeUniform extends Vector4Uniform {
  constructor(nodeUniform) {
    super(nodeUniform.name, nodeUniform.value);
    this.nodeUniform = nodeUniform;
  }

  getValue() {
    return this.nodeUniform.value;
  }

}

class ColorNodeUniform extends ColorUniform {
  constructor(nodeUniform) {
    super(nodeUniform.name, nodeUniform.value);
    this.nodeUniform = nodeUniform;
  }

  getValue() {
    return this.nodeUniform.value;
  }

}

class Matrix3NodeUniform extends Matrix3Uniform {
  constructor(nodeUniform) {
    super(nodeUniform.name, nodeUniform.value);
    this.nodeUniform = nodeUniform;
  }

  getValue() {
    return this.nodeUniform.value;
  }

}

class Matrix4NodeUniform extends Matrix4Uniform {
  constructor(nodeUniform) {
    super(nodeUniform.name, nodeUniform.value);
    this.nodeUniform = nodeUniform;
  }

  getValue() {
    return this.nodeUniform.value;
  }

}

export { ColorNodeUniform, FloatNodeUniform, Matrix3NodeUniform, Matrix4NodeUniform, Vector2NodeUniform, Vector3NodeUniform, Vector4NodeUniform };
